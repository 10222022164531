import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { login } from '../../api/api';

export const LoginPage = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            apiKey: '',
            apiSecret: '',
        },
        validationSchema: Yup.object({
            apiKey: Yup.string().required('Required'),
            apiSecret: Yup.string().required('Required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const data = await login(values.apiKey, values.apiSecret);
                localStorage.setItem('access_token', data.access_token);
                navigate('/transactions');
            } catch (error) {
                setErrors({ apiKey: 'Invalid login or password', apiSecret: 'Invalid login or password' });
                setSubmitting(false);
            }
        },
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5',
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Login
            </Typography>
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: 360,
                    p: 3,
                    backgroundColor: 'white',
                    borderRadius: 1,
                    boxShadow: 3,
                }}
            >
                <TextField
                    fullWidth
                    id="apiKey"
                    name="apiKey"
                    label="API Key"
                    margin="normal"
                    value={formik.values.apiKey}
                    onChange={formik.handleChange}
                    error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
                    helperText={formik.touched.apiKey && formik.errors.apiKey}
                />
                <TextField
                    fullWidth
                    id="apiSecret"
                    name="apiSecret"
                    label="API Secret"
                    type="password"
                    margin="normal"
                    value={formik.values.apiSecret}
                    onChange={formik.handleChange}
                    error={formik.touched.apiSecret && Boolean(formik.errors.apiSecret)}
                    helperText={formik.touched.apiSecret && formik.errors.apiSecret}
                />
                <Button color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                    Login
                </Button>
            </Box>
        </Box>
    );
};

