export const columns = [
    { field: "external_id", headerName: "Внешний ID", flex: 1.2, },
    {
      field: "payment_id",
      headerName: "ID платежа",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "Статус",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Стоимость (руб)",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "fee",
      headerName: "Комиссия (руб)",
      flex: 1,
    },
    {
      field: "payment_datetime",
      headerName: "Дата и время платежа",
      flex: 1,
      renderCell: ({ row }) => {
        // todo maybe day js to format
        return (
          new Date(row?.payment_datetime).toLocaleString('Ru-ru')
        );
      },
    },

    {
      field: "bank_name",
      headerName: "Банк",
      flex: 1,
    },
    {
      field: "card_number_short",
      headerName: "Номер карты",
      flex: 1,
    },
    {
      field: "card_holder_short",
      headerName: "Держатель карты",
      flex: 1,
    },
  ];
