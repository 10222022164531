import { Box, Button, TextField, Select, MenuItem, Snackbar } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { getBalance, requestWithdraw } from "../../api/api";
import { useFormik } from "formik";
import * as Yup from "yup";

const Form = () => {
  const [balance, setBalance] = useState(0);
  const [errorMessages, setErrorMessages] = useState({});
  const [errorMessagesOpen, setErrorMessagesOpen] = useState(false);
  const [type, setType] = useState('bank_card');

  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const formik = useFormik({
    initialValues: {
      amount: '',
      withdrawal_type: 'bank_card',
      card_number: '',
      phone_number: '',
      card_holder: '',
      bank_name: ''
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(1, "Amount must be greater than 0")
        .max(balance, `Amount cannot be more than ${balance}`)
        .required("Amount is required"),
      card_number: Yup.string().when('withdrawal_type', {
        is: 'bank_card',
        then: Yup.string().required('Card number is required').matches(/^[0-9]{16}$/, "Минимум 16 цифр")
      }),
      phone_number: Yup.string().when('withdrawal_type', {
        is: 'sbp',
        then: Yup.string().required('Phone number is required').matches(/^79\d{9}$/, "Phone number must start with '79' and contain 11 digits")
      }),
      card_holder: Yup.string().when('withdrawal_type', {
        is: (val) => val === 'bank_card' || val === 'sbp',
        then: Yup.string().required('Card holder is required')
      }),
      bank_name: Yup.string().when('withdrawal_type', {
        is: (val) => val === 'bank_card' || val === 'sbp',
        then: Yup.string().required('Bank name is required')
      })
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const data = {
          amount: values.amount,
          withdrawal_type: values.withdrawal_type,
          card_number: type !== 'sbp' ? values.card_number : undefined,
          phone_number: type === 'sbp' ? values.phone_number : undefined,
          card_holder: values.card_holder,
          bank_name: values.bank_name
        };
        await requestWithdraw(data);
        console.log("Withdraw request successful");
        setIsSuccessSnackbarOpen(true);
        resetForm();
        setErrorMessages({});
      } catch (error) {
        console.error("Error during withdraw request:", error);
        if (error.response && error.response.data) {
          const errors = error.response.data.detail?.reduce((acc, err) => {
            if (err.loc && err.loc.length > 1) {
              acc[err.loc[1]] = err.msg;
            }
            return acc;
          }, {});
          setErrorMessages(errors);
        }
      }
    }
  });

  const handleCloseSnackbar = () => {
    setIsSuccessSnackbarOpen(false);
  };


  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const data = await getBalance();
        setBalance(data || 0);
      } catch (error) {
        console.log({ error });
      }
    };

    fetchBalance();
  }, []);

  useEffect(() => {
    if (Object.keys(errorMessages).length) {
      setErrorMessagesOpen(true)
    }

  }, [errorMessages])

  const handleChangeType = (event) => {
    setType(event.target.value);
    formik.setFieldValue("withdrawal_type", event.target.value);
  };

  return (
    <Box m="20px">
      <Header title="Account Page" subtitle="Форма для вывода" />
      <Box
        display="grid"
        gridTemplateColumns={isNonMobile ? "repeat(9, 1fr)" : "repeat(1, 1fr)"}
        gridAutoRows="140px"
        gap="20px"
        my="10px"
      >
        <Box
          gridColumn={isNonMobile ? "span 3" : undefined}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox subtitle={balance} title="Баланс" progress="0.30" />
        </Box>
      </Box>

      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        display="grid"
        gap="30px"
        gridTemplateColumns={isNonMobile ? "repeat(9, 1fr)" : "repeat(1, 1fr)"}
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Box gridColumn={isNonMobile ? "span 3" : undefined}>
          <TextField
            fullWidth
            variant="filled"
            type="number"
            label="Сумма вывода"
            name="amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
          <Select
            fullWidth
            variant="filled"
            name="withdrawal_type"
            value={type}
            onChange={handleChangeType}
            onBlur={formik.handleBlur}
            error={formik.touched.withdrawal_type && Boolean(formik.errors.withdrawal_type)}
            sx={{ marginTop: '1rem' }}
          >
            <MenuItem value={'bank_card'}>Bank Card</MenuItem>
            <MenuItem value={'sbp'}>SBP</MenuItem>
          </Select>
          {type === "bank_card" && (
            <>
              <Box gridColumn={isNonMobile ? "span 3" : undefined}>
                <TextField
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  variant="filled"
                  type="text"
                  label="Card Number"
                  {...formik.getFieldProps('card_number')}
                  error={formik.touched.card_number && Boolean(formik.errors.card_number)}
                  helperText={formik.touched.card_number && formik.errors.card_number}
                />
              </Box>
              <Box gridColumn={isNonMobile ? "span 3" : undefined}>
                <TextField
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  variant="filled"
                  type="text"
                  label="Card Holder"
                  {...formik.getFieldProps('card_holder')}
                  error={formik.touched.card_holder && Boolean(formik.errors.card_holder)}
                  helperText={formik.touched.card_holder && formik.errors.card_holder}
                />
              </Box>
              <Box gridColumn={isNonMobile ? "span 3" : undefined}>
                <TextField
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  variant="filled"
                  type="text"
                  label="Bank Name"
                  {...formik.getFieldProps('bank_name')}
                  error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                  helperText={formik.touched.bank_name && formik.errors.bank_name}
                />
              </Box>
            </>
          )}

          {type === "sbp" && (
            <>
              <Box gridColumn={isNonMobile ? "span 3" : undefined}>
                <TextField
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  variant="filled"
                  type="text"
                  label="Phone Number"
                  {...formik.getFieldProps('phone_number')}
                  error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                  helperText={formik.touched.phone_number && formik.errors.phone_number}
                />
              </Box>
              <Box gridColumn={isNonMobile ? "span 3" : undefined}>
                <TextField
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  variant="filled"
                  type="text"
                  label="Card Holder"
                  {...formik.getFieldProps('card_holder')}
                  error={formik.touched.card_holder && Boolean(formik.errors.card_holder)}
                  helperText={formik.touched.card_holder && formik.errors.card_holder}
                />
              </Box>
              <Box gridColumn={isNonMobile ? "span 3" : undefined}>
                <TextField
                  fullWidth
                  sx={{ marginTop: '1rem' }}
                  variant="filled"
                  type="text"
                  label="Bank Name"
                  {...formik.getFieldProps('bank_name')}
                  error={formik.touched.bank_name && Boolean(formik.errors.bank_name)}
                  helperText={formik.touched.bank_name && formik.errors.bank_name}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box display="flex" mt="20px">
        <Button onClick={formik.handleSubmit} type="submit" color="secondary" variant="contained">
          Вывод
        </Button>
      </Box>
      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Запрос на вывод успешно отправлен"

      />
      {!!Object.keys(errorMessages).length && Object.keys(errorMessages).map(e => (
        <Snackbar
          open={errorMessagesOpen}
          autoHideDuration={9000}
          onClose={() => setErrorMessagesOpen(false)}
          message={errorMessages[e]}
          sx={{ color: 'red' }}
        />
      ))}
    </Box>
  );
};

export default Form;
