/* eslint-disable no-unused-vars */
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useEffect, useMemo, useState } from "react";
import StatBox from "../../components/StatBox";
import { getBalance, getTransactions } from "../../api/api";
import { columns } from "./columnsConfig";


const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState(0);
  // todo change when api changed
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(2000);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const memoData = useMemo(() => transactions.map((e, i) => ({ ...e, id: e.payment_id })), [transactions])


  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const data = await getTransactions({ page, pageSize });
        setTransactions(data || []);
      } catch (error) {
        console.warn({ error });
      }
    };

    fetchTransactions();
  }, [page, pageSize]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const data = await getBalance();
        setBalance(data || 0);
      } catch (error) {
        console.warn({ error });
      }
    };

    fetchBalance();
  }, []);
  

  return (
    <Box m="20px">
      <Box display="grid"
        gridTemplateColumns="repeat(9, 1fr)"
        gridAutoRows="140px"
        gap="20px" my="10px">
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="Баланс"
            subtitle={balance}
            progress="0.75"

          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Транзакции" subtitle="Таблица транзакций" />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Экспорт
          </Button>
        </Box>
      </Box>
      <Box
        m="10px 0 0 0"
        height="72vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={memoData} columns={columns}/>
      </Box>
    </Box>
  );
};

export default Transactions;
