import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Transactions from "./scenes/transactions";
import Form from "./scenes/form";
import { LoginPage } from "./scenes/login";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
      <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>}>
                        <Route path="account" element={<Form />} />
                        <Route path="transactions" element={<Transactions />} />
                    </Route>
                </Routes>
            </BrowserRouter>
  </React.StrictMode>
);
