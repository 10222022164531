import axios from "axios";

export const toBase64 = (input) => {
  return window.btoa(unescape(encodeURIComponent(input)));
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Basic ${btoa("admin:Mk8&YjZU4fJJtkVw@HZsQfaCTvn@#r2@")}`,
  },
});

export const getTransactions = async ({page,pageSize}) => {

  try {
    const response = await api.get(`/get-transactions?page=${page}&page_size=${pageSize}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};
export const getBalance = async () => {

  try {
    const response = await api.get(`/get-balance`);
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};


export const login = async (apiKey, apiSecret) => {
  try {
    const response = await api.post(`/login`, {
        api_key: apiKey,
        api_secret: apiSecret,
  });
    return response.data;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

export const requestWithdraw = async (data) => {
  try {
    const response = await api.post(`/request-withdraw`, data);
    return response.data;
  } catch (error) {
    console.error("Error requesting withdraw:", error);
    throw error;
  }
};
